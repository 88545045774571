@import "~@angular/material/theming";
@include mat-core();
@import "palettes";

$primary-50: mat-palette($mat-blue-nrj, 50);
$primary-100: mat-palette($mat-blue-nrj, 100);
$primary-200: mat-palette($mat-blue-nrj, 200);
$primary-300: mat-palette($mat-blue-nrj, 300);
$primary-400: mat-palette($mat-blue-nrj, 400);

.bg-primary-50 {
  background-color: mat-color($primary-50);
}
.bg-primary-100 {
  background-color: mat-color($primary-100);
}
.bg-primary-200 {
  background-color: mat-color($primary-200);
}
.bg-primary-300 {
  background-color: mat-color($primary-300);
}
.bg-primary-400 {
  background-color: mat-color($primary-400);
}
