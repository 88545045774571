@import "styles/variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";

@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";

@import "styles/reset";

@import "styles/colors";

html,
body {
  height: 100%;
  background-color: whitesmoke;
  max-width: 600px;
  margin: auto;
}

.bs-fullscreen {
  min-height: 100vh;
  padding: 0px !important;
  margin: 0px !important;
}

.dialog-no-padding .mat-dialog-container {
  padding: 0;
}

.dialog-backdrop-black {
  opacity: 0.85 !important;
  background-color: black;
}

.btn-bottom {
  position: fixed;
  bottom: 74px;
  display: flex;
  justify-content: end;
  width: 100%;
  max-width: 600px;
  padding-right: 1.5rem;
  pointer-events: none;
  & button {
    pointer-events: all;
  }
}

.main-container {
  height: calc(100% - 58px);
  overflow: auto;
  padding-top: 56px;
}

.content {
  padding: 16px 8px;
}

.mat-stroked-button,
.mat-flat-button {
  line-height: 48px !important;
}
